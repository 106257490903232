import React from 'react'

import Hero from '../components/homePageComponent/hero/Hero'

import Hero2 from '../components/homePageComponent/Hero2/Hero2'

function Home() {
  return (
    <div>
      {/* <Header/> */}
      <Hero/>
      <Hero2/>
      {/* <Footer/> */}
    </div>
  )
}

export default Home