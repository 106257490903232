import React from 'react'
// import About from '../components/homePageComponent/contact/About'
import About from '../components/homePageComponent/about/About'

 function AboutPage() {
  return (
    <div>
        <About/>
    </div>
  )
}

export default AboutPage